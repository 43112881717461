import { h, render } from 'preact'

import ConsentBannerContainer from '@components'

import './styles/font.css'
import { DOM_ROOT_ID } from './configs'

function App() {
  return <ConsentBannerContainer />
}

render(h(App), document.getElementById(DOM_ROOT_ID))
