import { useEffect } from 'preact/hooks'

export default function useTimeout(cookieSetting) {
  useEffect(() => {
    let timer = setTimeout(() => {
      if (
        window.consent_banner &&
        (!cookieSetting || Object.keys(cookieSetting).length === 0)
      ) {
        window.consent_banner.publish({})
      }
    }, 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [cookieSetting])
}
