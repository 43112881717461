import { useEffect } from 'preact/hooks'

import { isMobile } from '@utils/ua'

export default function useSetDeviceAttribute() {
  useEffect(() => {
    const htmlElement = document.getElementsByTagName('html')
    if (htmlElement && htmlElement[0]) {
      if (isMobile) {
        htmlElement[0].setAttribute('cb-device', 'mobile')
      } else {
        htmlElement[0].setAttribute('cb-device', 'desktop')
      }
    }
  }, [])
}
