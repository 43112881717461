import * as GTM from './gtm'
import * as DC from './dc'

export default function sendEventTracking({
  eventCategory,
  eventAction,
  eventLabel,
}) {
  GTM.logEvent({
    category: eventCategory,
    action: eventAction,
    label: eventLabel,
  })
  DC.logEvent({
    category: eventCategory,
    action: eventAction,
    label: eventLabel,
  })
}
