export function logEvent({ category, action, label }) {
  const customData = {
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  }
  if (typeof window.dc === 'function') {
    const dc = window.dc
    const dimensionOBJ = window.dimensionOBJ

    dc('send', 'event', {
      ...dimensionOBJ,
      event: 'eventTracker',
      ...customData,
    })
  }
}
