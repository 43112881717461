import {
  COOKIE_OPT_OUT_OPTION,
  IS_ACCEPT_CONSENT_BANNER,
  PERFORMANCE_COOKIE_OPT_OUT,
  ADVERTISING_COOKIE_OPT_OUT,
  POLICY_VERSION_KEY_NAME,
} from '@configs'

import { removeCookie } from '@utils/cookie'

import { getUserPolicyVersion } from './cookieSetting'
import { setCookieSetting, removeCookieSetting } from './sanookHubHelper'

const POLICY_VERSION = 5
export default function revalidatePolicy({
  cookieSetting,
  setCookieSetting: setInternalCookieSetting,
}) {
  const userPolicyVersion = getUserPolicyVersion(cookieSetting)
  const currentPolicyVersion = POLICY_VERSION

  const isUpToDate =
    userPolicyVersion && userPolicyVersion === currentPolicyVersion

  if (isUpToDate) {
    setInternalCookieSetting(cookieSetting || {})

    return false
  }

  // Clear storage related to consent banner
  removeCookieSetting()

  // Clear item related to performance cookie
  removeCookie(PERFORMANCE_COOKIE_OPT_OUT.SA, COOKIE_OPT_OUT_OPTION)
  removeCookie(PERFORMANCE_COOKIE_OPT_OUT.DC, COOKIE_OPT_OUT_OPTION)
  removeCookie(ADVERTISING_COOKIE_OPT_OUT, COOKIE_OPT_OUT_OPTION)

  // Increase next policy version
  setCookieSetting({
    [IS_ACCEPT_CONSENT_BANNER]: false,
    [POLICY_VERSION_KEY_NAME]: currentPolicyVersion,
  })

  setInternalCookieSetting({
    [IS_ACCEPT_CONSENT_BANNER]: false,
    [POLICY_VERSION_KEY_NAME]: currentPolicyVersion,
  })

  return true
}
