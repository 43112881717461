import { h } from 'preact'

import { useRef } from 'preact/hooks'

import { COOKIE_SETTING_KEY, ELEMENT_ID } from '@configs'

import ConsentBannerController from './ConsentBannerController'

import style from './style.module.css'

export default function ConsentBannerContainer() {
  const iframeRef = useRef()

  const onIframeLoad = () => {
    requestAnimationFrame(() => {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage(
          JSON.stringify({ key: COOKIE_SETTING_KEY, method: 'get' }),
          '*',
        )
      }
    })
  }

  return (
    <div>
      <iframe
        ref={iframeRef}
        onLoad={onIframeLoad}
        id={ELEMENT_ID.SANOOK_HUB}
        src="https://www.sanook.com/cross-storage/hub.html"
        className={style.hubIframe}></iframe>
      <ConsentBannerController />
    </div>
  )
}
