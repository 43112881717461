import { useEffect } from 'preact/hooks'

import { COOKIE_SETTING_TYPE, COOKIE_SETTING_KEY } from '@configs'

import revalidatePolicy from '../utils/revalidatePolicy'
import updateSaAndDcCookie from '../utils/updateSaAndDcCookie'
import updateAdsCookie from '../utils/updateAdsCookie'
import getOptOutStatus from '../utils/getOptOutStatus'
import { checkShouldShowConsentBanner } from '../utils/cookieSetting'

export default function useListenMessageFromSanookHub({
  setShouldShowConsentBanner,
  cookieSetting,
  setCookieSetting,
}) {
  useEffect(() => {
    const handleMessage = (event) => {
      const isSanook = /https?:\/\/([a-z.-]+)\.i?sanook\.com/g.test(
        event.origin,
      )
      const isWWW = event.origin.indexOf('www') !== -1

      if (!isSanook || !isWWW) {
        return
      }

      let receiveMessage
      try {
        receiveMessage =
          typeof event.data === 'string' ? JSON.parse(event.data) : event.data
      } catch (e) {
        return
      }

      if (
        receiveMessage &&
        receiveMessage.method === 'storage' &&
        receiveMessage.key === COOKIE_SETTING_KEY
      ) {
        const updatedCookieSetting = {
          ...cookieSetting,
          ...receiveMessage.data,
        }

        if (window.consent_banner) {
          window.consent_banner.publish(updatedCookieSetting)
        }

        const isRevalidate = revalidatePolicy({
          cookieSetting: updatedCookieSetting,
          setCookieSetting,
          setShouldShowConsentBanner,
        })

        if (!isRevalidate) {
          const shouldShowConsentBanner =
            checkShouldShowConsentBanner(updatedCookieSetting)

          setShouldShowConsentBanner(shouldShowConsentBanner)

          const saAndDcOptOutStatus = getOptOutStatus(
            updatedCookieSetting[COOKIE_SETTING_TYPE.performance],
          )
          updateSaAndDcCookie(saAndDcOptOutStatus)

          const adsOptOutStatus = getOptOutStatus(
            updatedCookieSetting[COOKIE_SETTING_TYPE.advertising],
          )
          updateAdsCookie(adsOptOutStatus)
        } else {
          setShouldShowConsentBanner(true)
        }
      }
    }

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [cookieSetting])
}
