import { h } from 'preact'

import { useCallback, useState } from 'preact/hooks'
import { Suspense, lazy } from 'preact/compat'

import sendEventTracking from '@utils/stats/sendEventTracking'

import {
  COOKIE_STATUS,
  IS_ACCEPT_CONSENT_BANNER,
  COOKIE_SETTING_TYPE,
} from '@configs'

import { refreshCookieSetting, setCookieSetting } from './utils/sanookHubHelper'
import updateSaAndDcCookie from './utils/updateSaAndDcCookie'
import updateAdsCookie from './utils/updateAdsCookie'

import useTimeout from './hooks/useTimeout'
import useSetDeviceAttribute from './hooks/useSetDeviceAttribute'
import useConsentActive from './hooks/useConsentActive'
import useListenMessageFromSanookHub from './hooks/useListenMessageFromSanookHub'
import useIsApp from './hooks/useIsApp'

const Template = lazy(() => import('./Template'))

const { performance, targeting, advertising } = COOKIE_SETTING_TYPE

export default function ConsentBannerController() {
  const [shouldShowConsentBanner, setShouldShowConsentBanner] = useState(false)
  const [isOpenCookieSetting, setIsOpenCookieSetting] = useState(false)
  const [cookieSetting, setInternalCookieSetting] = useState({})
  const isApp = useIsApp()

  useSetDeviceAttribute()
  useTimeout(cookieSetting)
  useConsentActive(shouldShowConsentBanner)

  useListenMessageFromSanookHub({
    cookieSetting,
    setShouldShowConsentBanner,
    setCookieSetting: setInternalCookieSetting,
  })

  const handleOnClickAccept = useCallback(() => {
    sendEventTracking({
      eventCategory: 'gdprnoti',
      eventAction: 'accept',
      eventLabel: 'gdprnoti-acceptcookies',
    })

    setCookieSetting({
      ...cookieSetting,
      [IS_ACCEPT_CONSENT_BANNER]: true,
      [targeting]: true,
      [performance]: true,
      [advertising]: true,
    })

    refreshCookieSetting()

    updateSaAndDcCookie(COOKIE_STATUS.ACCEPT)
    updateAdsCookie(COOKIE_STATUS.ACCEPT)

    setShouldShowConsentBanner(false)
    setIsOpenCookieSetting(false)
  }, [cookieSetting, shouldShowConsentBanner])

  const handleOnClickPolicyLink = useCallback(() => {
    sendEventTracking({
      eventCategory: 'gdprnoti',
      eventAction: 'policy',
      eventLabel: 'gdprnoti-policy',
    })
  }, [])

  const handleOnClickCookiePolicyLink = useCallback(() => {
    sendEventTracking({
      eventCategory: 'gdprnoti',
      eventAction: 'cookiepolicy',
      eventLabel: 'gdprnoti-cookiepolicy',
    })
  }, [])

  const handleOnOpenCookieSetting = useCallback(() => {
    sendEventTracking({
      eventCategory: 'gdprnoti',
      eventAction: 'cookiepolicy',
      eventLabel: 'gdprnoti-cookiesetting',
    })

    setIsOpenCookieSetting(true)
  }, [isOpenCookieSetting])

  return (
    <Suspense fallback={null}>
      <Template
        cookieSetting={cookieSetting}
        shouldShowConsentBanner={shouldShowConsentBanner}
        setShouldShowConsentBanner={setShouldShowConsentBanner}
        isOpenCookieSetting={isOpenCookieSetting}
        setIsOpenCookieSetting={setIsOpenCookieSetting}
        handleOnClickAccept={handleOnClickAccept}
        handleOnClickPolicyLink={handleOnClickPolicyLink}
        handleOnClickCookiePolicyLink={handleOnClickCookiePolicyLink}
        handleOnOpenCookieSetting={handleOnOpenCookieSetting}
        isApp={isApp}
      />
    </Suspense>
  )
}
