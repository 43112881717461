import { useState, useEffect } from 'preact/hooks'

export default function useIsApp() {
  const [isApp, setIsApp] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (
      params.get('utm_source') === 'sanook' &&
      params.get('utm_medium') === 'app'
    ) {
      setIsApp(true)
    }
  }, [])

  return isApp
}
