import { COOKIE_STATUS } from '@configs'

export default function getOptOutStatus(updatedCookieSetting) {
  let status
  if (updatedCookieSetting === true) {
    status = COOKIE_STATUS.ACCEPT
  } else if (updatedCookieSetting === false) {
    status = COOKIE_STATUS.OPT_OUT
  } else {
    status = COOKIE_STATUS.IGNORE
  }

  return status
}
