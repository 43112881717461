const variableNames = {
  event: {
    category: 'eventCategory',
    action: 'eventAction',
    label: 'eventLabel',
  },
}

function isDataInvalid(data) {
  return typeof data?.event !== 'string'
}

function gtmPush(data) {
  const dataLayer = window.dataLayer

  if (typeof dataLayer !== 'undefined' && !isDataInvalid(data)) {
    dataLayer.push(data)
  }
}

export function logEvent({ category, action, label }) {
  const { event } = variableNames

  const customData = {
    [event.category]: category,
    [event.action]: action,
    [event.label]: label,
  }

  gtmPush({
    event: 'eventTracker',
    ...customData,
  })
}
