import { COOKIE_SETTING_KEY, ELEMENT_ID } from '@configs'

export const refreshCookieSetting = () => {
  const iframe = document.getElementById(ELEMENT_ID.SANOOK_HUB)
  if (iframe) {
    iframe.contentWindow.postMessage(
      JSON.stringify({ key: COOKIE_SETTING_KEY, method: 'get' }),
      '*',
    )
  }
}

export const setCookieSetting = (value) => {
  const cookieSettingValue = JSON.stringify(value)

  const iframe = document.getElementById(ELEMENT_ID.SANOOK_HUB)
  if (iframe) {
    iframe.contentWindow.postMessage(
      JSON.stringify({
        key: COOKIE_SETTING_KEY,
        method: 'set',
        data: cookieSettingValue,
      }),
      '*',
    )
  }
}

export const removeCookieSetting = (value) => {
  const iframe = document.getElementById(ELEMENT_ID.SANOOK_HUB)
  if (iframe) {
    iframe.contentWindow.postMessage(
      JSON.stringify({
        key: COOKIE_SETTING_KEY,
        method: 'remove',
      }),
      '*',
    )
  }
}
