import {
  COOKIE_SETTING_TYPE,
  IS_ACCEPT_CONSENT_BANNER,
  POLICY_VERSION_KEY_NAME,
} from '@configs'

export const checkIsOptOutPerformanceCookie = (cookieSetting) => {
  if (!cookieSetting) {
    return false
  }

  return cookieSetting[COOKIE_SETTING_TYPE.performance] === false
}

export const getUserNonPersonalizedAdsFlagFromCookieSetting = (
  cookieSetting,
) => {
  if (!cookieSetting) {
    return
  }

  if (cookieSetting[COOKIE_SETTING_TYPE.advertising] === true) {
    return 0
  }

  if (cookieSetting[COOKIE_SETTING_TYPE.advertising] === false) {
    return 1
  }
}

export const getUserPolicyVersion = (cookieSetting) => {
  if (!cookieSetting) {
    return
  }

  return cookieSetting[POLICY_VERSION_KEY_NAME]
}

export const checkShouldShowConsentBanner = (cookieSetting) => {
  return !cookieSetting[IS_ACCEPT_CONSENT_BANNER]
}
