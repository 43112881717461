export const DOM_ROOT_ID = 'sanook-consent-banner-root'

export const ELEMENT_ID = {
  SANOOK_HUB: 'sanook_hub',
}

export const SUPPORT_LANG = {
  EN: 'en',
  TH: 'th',
}

export const CONSENT_BANNER_ATTRIBUTE = {
  LANG: 'lang',
}

export const COOKIE_SETTING_KEY = 'cookie_setting'
export const COOKIE_SETTING_TYPE = {
  sanook_website: 'sanook_website_pdpa',
  performance: 'performance_pdpa',
  targeting: 'targeting_pdpa',
  advertising: 'advertising_pdpa',
}

export const IS_ACCEPT_CONSENT_BANNER = 'is_accept_consent_banner'

export const POLICY_VERSION_KEY_NAME = 'policy_version'

export const PERFORMANCE_COOKIE_OPT_OUT = {
  SA: 'sa_optout',
  DC: 'dc_optout',
}

export const ADVERTISING_COOKIE_OPT_OUT = 'ads_optout'

const COOKIE_OPT_OUT_TIME = 31536000 // 1 year
export const COOKIE_OPT_OUT_OPTION = {
  domain: '.sanook.com',
  path: '/',
  maxAge: COOKIE_OPT_OUT_TIME,
}

export const COOKIE_STATUS = {
  ACCEPT: 0,
  OPT_OUT: 1,
  IGNORE: 2,
}
