import { useEffect } from 'preact/hooks'

export default function useConsentActive(shouldShowConsentBanner) {
  useEffect(() => {
    const htmlElement = document.getElementsByTagName('html')
    if (htmlElement && htmlElement[0]) {
      if (shouldShowConsentBanner) {
        htmlElement[0].classList.add('consent-active')
      } else {
        htmlElement[0].classList.remove('consent-active')
      }
    }
  }, [shouldShowConsentBanner])
}
